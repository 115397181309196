<template>
  <el-dialog :visible="true" custom-class="them-dialog" @close="$emit('close')" width="32rem">
    <div slot="title">
      修改密码
    </div>
    <el-form :model="form" ref="form" label-width="6rem" :rules="rules">
      <el-form-item label="旧密码:" prop="oldPassword" v-if="hasPassword">
        <el-input type='password' clearable v-model="form.oldPassword" autocomplete="off" placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码:" prop="newPassword">
        <el-input type='password' clearable v-model="form.newPassword" autocomplete="off" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" prop="pass">
        <el-input type='password' clearable v-model="form.pass" autocomplete="off" placeholder="请再次输入新密码"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import CryptoJS from 'crypto-js'
export default {
  props: {
    hasPassword: {
      type: Boolean,
      default: true
    }
  },
  data () {
    var validatePass = (rule, value, callback) => {
      value = value.trim()
      if (this.form.newPassword !== value) {
        callback(new Error('两次密码不一致，请重新填写'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPassword: '',
        newPassword: ''
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async submit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          // delete this.form.pass
          const res = await this.$api.modifyPwd(
            Object.assign({}, this.form, { oldPassword: this.form.oldPassword ? CryptoJS.MD5(this.form.oldPassword).toString() : '' }, { newPassword: this.form.newPassword ? CryptoJS.MD5(this.form.newPassword).toString() : '' })
          )
          if (res !== 'fail&&error') {
            this.$emit('close', true)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
