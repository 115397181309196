<template>
  <div class="header-component flex ai-center" style="color: #5B5B5B;">
    <div
      :style="sysNameStyle"
      class="pointer flex ai-center"
      @click="$router.push('/system')"
    >
      <i class="header-icon"></i>
      <span v-show="!$store.state.menu.collapse" style="padding-left: .5rem;">{{
        $store.state.sysName
      }}</span>
    </div>
    <div class="flex jc-between" style="flex: 1;padding: 0 20px 0 0;">
      <div style="font-size: 1.5rem;">
        <i
          :class="[
            !$store.state.menu.collapse ? 'icon-zhedie1' : 'icon-zhedie2',
            'iconfont',
            'pointer'
          ]"
          style="margin-right: .6rem;font-size: 1.5rem;"
          @click="$store.commit('setCollapse')"
        ></i>
        <i class="el-icon-refresh-right pointer" @click="$router.go(0)"></i>
      </div>
      <div class="center">
        <i
          :class="['el-icon-bell', 'message', true && 'active']"
          style="font-size: 1.38rem;"
        ></i>
        <span style="padding:0 .6rem 0 1rem;font-size: .9rem;">{{name}}</span>

        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down pointer" style="font-size: .9rem;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="reset" icon="el-icon-key"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item command="exit" icon="el-icon-switch-button"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <resetPass v-if="showResetPass" @close="editPass" :hasPassword='hasPassword'></resetPass>
  </div>
</template>

<script>
import resetPass from './resetPass.vue'
export default {
  data () {
    return {
      showResetPass: false,
      name: '',
      hasPassword: ''
    }
  },
  components: {
    resetPass
  },
  computed: {
    sysNameStyle () {
      return {
        width: !this.$store.state.menu.collapse ? 'auto' : '4rem',
        minWidth: !this.$store.state.menu.collapse ? '14rem' : '4rem',
        padding: '0 1.28rem',
        fontSize: '1.1rem',
        fontWeight: 600,
        color: '#8B94A0'
      }
    }

  },
  created () {
    this.getInfo()
  },
  methods: {
    handleCommand (cammand) {
      if (cammand === 'exit') {
        this.$confirm('您确认要退出登录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
          .then(async () => {
            const res = await this.$api.logout()
            if (res !== 'fail&&error') {
              this.$store.dispatch('logOut')
            }
          })
          .catch(() => {})
      } else {
        this.showResetPass = true
      }
    },
    editPass (val) {
      this.showResetPass = false
      this.hasPassword = val
    },
    async getInfo () {
      const res = await this.$api.simpleInfo(
        Object.assign({})
      )
      if (res !== 'fail&&error') {
        this.name = res.name
        this.hasPassword = res.hasPassword
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-component {
  height: 3.25rem;
  line-height: 3.25rem;
  .header-icon {
    display: inline-block;
    width: 1.38rem;
    height: 1.38rem;
    background-size: cover;
    background-image: url("../../../assets/img/invalid-name@3x.png");
  }
  .message {
    position: relative;
    &.active::after {
      content: "";
      position: absolute;
      right: 0.16rem;
      top: 0.16rem;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: #77efb7;
    }
  }
}
</style>
