<template>
  <div class="tab-component flex">
    <div class="border" @click="scroll('left')">
      <i style="color: #8B94A0;" class="el-icon-arrow-left"></i>
    </div>
    <div class="flex" style="flex: 1;">
      <div
        :class="[
          'tab-item',
          'pointer',
          $store.state.tab.activeIndex === -1 && 'active'
        ]"
        @click="page('/system', -1)"
      >
        首页
      </div>
      <div class="tabs" ref="tabs">
        <div
          :class="[
            'tab-item',
            $store.state.tab.activeIndex === tabIndex && 'active'
          ]"
          v-for="(tab, tabIndex) in $store.state.tab.tabArray"
          :key="tabIndex"
        >
          <span class="pointer" @click="page(tab.router, tabIndex)">{{
            tab.name
          }}</span>
          <i
            style="color: #8B94A0;margin-left: .38rem;"
            class="el-icon-close pointer"
            @click="close(tabIndex)"
          ></i>
        </div>
      </div>
    </div>
    <div
      class="border"
      style="border-left: .5px solid #F1F3F8;"
      @click="scroll('right')"
    >
      <i style="color: #8B94A0;" class="el-icon-arrow-right"></i>
    </div>
    <div class="border">
      <el-dropdown trigger="click" @command="closeOther">
        <span class="el-dropdown-link">
          <i class="el-icon-arrow-down" style="color: #8B94A0;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="other" icon="el-icon-close"
            >关闭其他</el-dropdown-item
          >
          <el-dropdown-item command="right" icon="el-icon-right"
            >关闭右侧</el-dropdown-item
          >
          <el-dropdown-item command="left" icon="el-icon-back"
            >关闭左侧</el-dropdown-item
          >
          <el-dropdown-item command="all" icon="el-icon-circle-close"
            >关闭全部</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    page (path, index) {
      this.$store.commit('setActiveIndex', index)
      this.$router.push(path)
    },
    close (index) {
      // 删除当前项页面也要跳转到对应的页面
      if (index === 0) {
        if (this.$store.state.tab.tabArray.length === 1) {
          this.$router.push('/system')
        } else {
          this.$router.push(this.$store.state.tab.tabArray[1].router)
        }
      } else {
        this.$router.push(this.$store.state.tab.tabArray[index - 1].router)
      }
      // 删除当前项
      this.$store.commit('tabDel', index)
    },
    closeOther (type) {
      if (type === 'all') {
        this.$router.push('/system')
      }
      this.$store.commit('tabDelMul', type)
    },
    scroll (type) {
      if (type === 'right') {
        this.$refs.tabs.scrollLeft += 50
      } else {
        this.$refs.tabs.scrollLeft -= 50
      }
    },
    scroll2 (index) {
      this.$refs.tabs.scrollLeft = index * 96
    }
  },
  watch: {
    '$store.state.tab.tabArray' () {
      this.$nextTick(() => {
        this.$refs.tabs.scrollLeft = this.$refs.tabs.scrollWidth
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-component {
  height: 2.6rem;
  overflow: hidden;
  line-height: 2.6rem;
  border-left: .5px solid #f1f3f8;
  border-bottom: .5px solid #f1f3f8;
  .border {
    cursor: pointer;
    padding: 0 0.3rem;
    border-right: .5px solid #f1f3f8;
  }
  .tab-item {
    display: inline-block;
    width: 6rem;
    text-align: center;
    color: #8b94a0;
    font-size: 0.8rem;
    font-weight: 600;
    border-right: .5px solid #f1f3f8;

    &.active {
      color: #40a3ff;
    }
  }
  .tabs {
    flex: 1;
    width: 1px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    line-height: 2.4rem;
  }
}
</style>
