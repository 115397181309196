<template>
  <div>
    <Layout></Layout>
  </div>
</template>

<script>
import Layout from '../layout'
export default {
  components: {
    Layout,
  },
  created() {
    if (!this.$store.state.tenantId) {
      this.getSysName()
    }
  },
  methods: {
    async getSysName() {
      const res = await this.$api.getSysName({
        domain: `${window.location.protocol}//${window.location.host}`,
      })
      if (res !== 'fail&&error') {
        this.$store.commit('SET_TID', res.tenantId)
        this.$store.commit('SET_SYS_NAME', res.sysName)
        this.$store.commit('SET_WELCOME_IMAGE', res.welcomeImageUrl)
        document.title = res.sysName
      } else {
        this.$store.commit('SET_TID')
        this.$store.commit('SET_SYS_NAME')
        this.$store.commit('SET_WELCOME_IMAGE')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
