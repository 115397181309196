var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-component flex"},[_c('div',{staticClass:"border",on:{"click":function($event){return _vm.scroll('left')}}},[_c('i',{staticClass:"el-icon-arrow-left",staticStyle:{"color":"#8B94A0"}})]),_c('div',{staticClass:"flex",staticStyle:{"flex":"1"}},[_c('div',{class:[
        'tab-item',
        'pointer',
        _vm.$store.state.tab.activeIndex === -1 && 'active'
      ],on:{"click":function($event){return _vm.page('/system', -1)}}},[_vm._v(" 首页 ")]),_c('div',{ref:"tabs",staticClass:"tabs"},_vm._l((_vm.$store.state.tab.tabArray),function(tab,tabIndex){return _c('div',{key:tabIndex,class:[
          'tab-item',
          _vm.$store.state.tab.activeIndex === tabIndex && 'active'
        ]},[_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.page(tab.router, tabIndex)}}},[_vm._v(_vm._s(tab.name))]),_c('i',{staticClass:"el-icon-close pointer",staticStyle:{"color":"#8B94A0","margin-left":".38rem"},on:{"click":function($event){return _vm.close(tabIndex)}}})])}),0)]),_c('div',{staticClass:"border",staticStyle:{"border-left":".5px solid #F1F3F8"},on:{"click":function($event){return _vm.scroll('right')}}},[_c('i',{staticClass:"el-icon-arrow-right",staticStyle:{"color":"#8B94A0"}})]),_c('div',{staticClass:"border"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.closeOther}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"color":"#8B94A0"}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"other","icon":"el-icon-close"}},[_vm._v("关闭其他")]),_c('el-dropdown-item',{attrs:{"command":"right","icon":"el-icon-right"}},[_vm._v("关闭右侧")]),_c('el-dropdown-item',{attrs:{"command":"left","icon":"el-icon-back"}},[_vm._v("关闭左侧")]),_c('el-dropdown-item',{attrs:{"command":"all","icon":"el-icon-circle-close"}},[_vm._v("关闭全部")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }