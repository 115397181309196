<template>
  <div class="page layout-page">
    <div class="layout-header">
      <Header></Header>
    </div>
    <div class="flex">
      <div
        class="layout-menu"
        :style="{
          width: !$store.state.menu.collapse ? '12.5rem' : '4rem',
          minWidth: !$store.state.menu.collapse ? '12.5rem' : '4rem',
        }"
      >
        <Menu></Menu>
      </div>
      <div class="layout-main">
        <div class="layout-tab">
          <Tab ref="tab"></Tab>
        </div>
        <div
          :class="[
            !$store.state.menu.collapse ? 'layout-content' : 'layout-content2',
          ]"
        >
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './header'
import Menu from './menu'
import Tab from './tab'
export default {
  data() {
    return {}
  },

  components: {
    Header,
    Menu,
    Tab,
  },
  methods: {
    scrollTab(index) {
      this.$refs.tab.scroll2(index)
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-page {
  .layout-header {
    height: 3.75rem;
    background-color: #fff;
    border-bottom: 2px solid #f9fafb;
  }
  .layout-menu {
    height: calc(100vh - 3.75rem);
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 150ms linear;
  }
  .layout-main {
    .layout-tab {
      height: 2.6rem;
    }
    .layout-content {
      width: calc(100vw - 12.5rem);
      height: calc(100% - 2.6rem);
    }
    .layout-content2 {
      width: calc(100vw - 4rem);
      height: calc(100% - 2.6rem);
    }
  }
}
</style>
