<template>
  <el-menu
    router
    @select="tabAdd"
    @open="handleOpen"
    @close="handleClose"
    text-color="#8B9AAD"
    active-text-color="#2A98FE"
    :collapse-transition="false"
    :default-openeds="$store.state.menu.defaultOpeneds"
    :default-active="defaultActive"
    :collapse="$store.state.menu.collapse"
  >
    <!--  -->
    <!-- <el-menu-item index="/system/maintenanceService">
      <i class="menu-icon"></i>
      <span>维保服务</span>
    </el-menu-item> -->
    <!--  -->
    <template v-for="(item, index) in menuList">
      <template v-if="!item.url">
        <el-submenu :key="index" :index="item.tenantResourceId">
          <template slot="title">
            <i
              class="menu-icon"
              :style="{
                background: 'url(' + item.icon + ')   no-repeat',
                backgroundSize: 'contain',
              }"
            ></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item
            :index="ele.url"
            v-for="(ele, i) in item.children"
            :key="i"
          >
            <span style="padding: 0 5px"></span>
            {{ $store.state.menu.menus[ele.url] }}</el-menu-item
          >
        </el-submenu>
      </template>
      <template v-else>
        <el-menu-item :key="index" :index="item.url">
          <i
            class="menu-icon"
            :style="{
              background: 'url(' + item.icon + ') no-repeat',
              backgroundSize: 'contain',
            }"
          ></i>
          <span>{{ $store.state.menu.menus[item.url] }}</span></el-menu-item
        >
      </template>
    </template>
  </el-menu>
</template>

<script>
export default {
  data () {
    return {
      menuList: []
    }
  },

  computed: {
    defaultActive () {
      if (this.$store.state.tab.activeIndex === -1) {
        return ''
      } else {
        return this.$store.state.tab.tabArray[this.$store.state.tab.activeIndex]
          .router
      }
    }
  },
  created () {
    this.menusList()
  },
  methods: {
    tabAdd (key, keyPath) {
      const index = this.$store.state.tab.tabArray.findIndex(
        (item) => item.router === key
      )
      if (index === -1) {
        this.$store.commit('tabAdd', {
          router: key,
          name: this.$store.state.menu.menus[key]
        })
      } else {
        this.$store.commit('setActiveIndex', index)
        this.$parent.scrollTab(index)
      }
    },
    handleOpen (key) {
      this.$store.commit('updateOpeneds', {
        type: 'open',
        data: key
      })
    },
    handleClose (key) {
      this.$store.commit('updateOpeneds', {
        type: 'close',
        data: key
      })
    },
    async menusList () {
      const res = await this.$api.menusList(this.searchForm)
      if (res !== 'fail&&error') {
        this.menuList = res
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-icon {
  display: inline-block;
  width: 1.38rem;
  height: 1.38rem;
  margin-right: 0.5rem;
  background-size: cover;
  &.user-icon {
    background-image: url("../../../assets/img/user@3x.png");
  }
  &.car-icon {
    background-image: url("../../../assets/img/car@3x.png");
  }
  &.chart-icon {
    background-image: url("../../../assets/img/line-chart@3x.png");
  }
  &.control-icon {
    background-image: url("../../../assets/img/control@3x.png");
  }
}
</style>
